export default (queryString) => {
  const params = new URLSearchParams(queryString)
  const converted = {}

  for (const [key, value] of params) {
    const tmpValue = value === 'null' ? null : value

    if (!converted[key]) {
      if (key === 'tags') {
        const splitted = tmpValue.split(',')
        converted[key] = tmpValue ? splitted : []
      } else {
        converted[key] = tmpValue
      }
    } else if (Array.isArray(converted[key])) {
      converted[key].push(tmpValue)
    } else {
      converted[key] = [converted[key], tmpValue]
    }
  }

  return converted
}
